<script>
import { masterComputed, masterMethods, clientMethods } from '@/state/helpers';
import { checkPermission, checkPermissionName } from '@/utils/handlers';
import { cleanConfigFormData } from '@/utils/format';
import { getAddressByZip, getPostCodeByAddress } from 'postcode-address-get';
import { showMessage } from '@/utils/messages';
import { initFormClient } from './index';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateAllField, validateField } from '@/utils/validate';
import { OPTIONS_TYPE } from '@/components/Campaign/TabClients/constants';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Company from '@/components/Company/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    page: {
        title: 'エンドクライアント新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Company,
        Layout,
        KeyWordTag,
        Footer,
        InputText,
        InputCheckBox,
        Multiselect
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: initFormClient(),
            configKeyword: {
                error: false,
                arrayTag: []
            },
            arrRoleClient: ['client.register', 'client.edit'],
            OPTIONS_TYPE: OPTIONS_TYPE,
            listOptionType: [],
            keyOptionType: []
        };
    },
    computed: {
        ...masterComputed
    },

    async mounted() {
        if (this.$route.query.id) {
            await this.getListOptionType();
            this.getDetail(this.$route.query.id);
        } else {
            this.getListOptionType();
        }
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,

        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        getDetail(id) {
            this.loading = true;
            this.detailClient(id).then((data) => {
                this.form = {
                    ...this.form,
                    ...data,
                    type_id: OPTIONS_TYPE.find((mop) => mop.id == data.type_id) ?? null,
                    option_type: data?.option_type?.map((dop) => dop.id) ?? []
                };
                this.loading = false;
                this.configKeyword.arrayTag = data.keywords;
                this.handleSelectTypeId();
            });
        },

        formSubmit() {
            let check = validateAllField(this.form);
            if (this.configKeyword.error || !check) {
                return;
            } else {
                this.register();
            }
        },

        register() {
            this.loading = true;
            let dataObject = cleanConfigFormData(this.form);
            dataObject.keywords = this.configKeyword.arrayTag;
            if (dataObject.type_id) {
                dataObject.type_id = dataObject.type_id.id;
            }
            if (this.$route.query.id) {
                this.updateClient(dataObject).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, 'エンドクライアントが更新されました。');
                        this.getDetail(this.$route.query.id);
                    } else {
                        if (data.message === 'name.unique') {
                            showMessage(data.code, this.$bvToast, this.$t('client.name_unique'));
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    }
                });
            } else {
                this.registerClient(dataObject).then((data) => {
                    if (data.code == 200) {
                        this.$router
                            .push({
                                path: '/client/form/view',
                                query: {
                                    id: data.data.id
                                }
                            })
                            .then(() => {
                                showMessage(data.code, this.$bvToast, 'エンドクライアントが登録されました');
                            });
                    } else {
                        if (data.message === 'name.unique') {
                            showMessage(data.code, this.$bvToast, this.$t('client.name_unique'));
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    }
                });
            }
        },

        inputCode() {
            if (!this.form.postal_code) {
                this.form.province = this.form.address_1 = '';
            } else {
                if (this.form.postal_code.length < 3) return;
                getAddressByZip(this.form.postal_code, (address) => {
                    if (address.status == 200) {
                        this.form.province = address.prefecture;
                        this.form.address_1 = address.city + address.area;
                    }
                });
            }
        },

        inputAddress() {
            getPostCodeByAddress(this.form.province + this.form.address_1, (dataPostcode) => {
                if (dataPostcode.status == 200) this.form.postal_code = dataPostcode.postcode;
            });
        },

        handleData(data) {
            if (data) {
                this.form.name = data.name;
                this.form.corp_number = data.corporate_number;
                this.form.kana_name = data.furigana;
                this.form.postal_code = data.post_code;
                this.form.address_1 = data.city ? data.city.name : '';
                this.form.address_2 = data.street_number;
                this.form.province = data.prefecture ? data.prefecture.name : '';
            }
        },

        inputCorporate() {
            let query = [`method=number`, `number=${this.form.corp_number}`];
            this.findCompany(query.join('&')).then((data) => {
                if (data.code == 200) {
                    this.handleData(data.data[0]);
                }
            });
        },

        handleSelectTypeId(typeHandle) {
            if (typeHandle == 'select') this.form.option_type = [];
            if (!this.form?.type_id?.id) return;
            let keyMasterParam =
                this.form?.type_id?.id == 1 ? 'client.agency_type' : this.form?.type_id?.id == 2 ? 'client.maker_type' : 'client.circulate_type';
            this.listOptionType = this.keyOptionType[keyMasterParam];
        },

        async getListOptionType() {
            this.loading = true;
            try {
                const data = await this.listMaster('client/common-client-type');
                this.keyOptionType = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },

        handleDeleteTypeID() {
            this.listOptionType = [];
            this.form.option_type = [];
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleGetDetail() {
            this.$router.push({
                path: '/client/form/view',
                query: {
                    id: this.$route.query.id
                }
            });
        }
    },

    watch: {
        '$route.query.id': {
            handler: async function (newId) {
                if (!newId) {
                    this.form = initFormClient();
                }
            },
            immediate: true
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading" class="row form__body--card form__common--center">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <div style="position: absolute; left: 100%; top: 5px">
                                                <company @data="handleData" />
                                            </div>
                                            <InputText
                                                :model.sync="form.name"
                                                :config.sync="form.configFieldName"
                                                @blur="handleValidateField(form.configFieldName)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.kana_name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.kana_name"
                                                :config.sync="form.configFieldKanaName"
                                                @blur="handleValidateField(form.configFieldKanaName)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">法人番号</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :model.sync="form.corp_number"
                                                :config.sync="form.configFieldCorpNumber"
                                                @change="inputCorporate"
                                                @blur="handleValidateField(form.configFieldCorpNumber)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.phone_number') }}</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.phone_number"
                                                :config.sync="form.configFieldPhoneNumber"
                                                @blur="handleValidateField(form.configFieldPhoneNumber)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.postal_code') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.postal_code"
                                                :config.sync="form.configFieldPostalCode"
                                                @input="inputCode"
                                                @blur="handleValidateField(form.configFieldPostalCode)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.province') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.province"
                                                :config.sync="form.configFieldProvince"
                                                @input="inputAddress"
                                                @blur="handleValidateField(form.configFieldProvince)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.address_1') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.address_1"
                                                :config.sync="form.configFieldAddress1"
                                                @input="inputAddress"
                                                @blur="handleValidateField(form.configFieldAddress1)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.address_2') }}</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.address_2"
                                                :config.sync="form.configFieldAddress2"
                                                @blur="handleValidateField(form.configFieldAddress2)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.profile') }}</label>
                                        <div class="col-sm-9">
                                            <textarea rows="4" id="validationCustom05" v-model="form.profile" class="form-control"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.url') }}</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="form.url"
                                                :config.sync="form.configFieldUrl"
                                                @blur="handleValidateField(form.configFieldUrl)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.keyword') }}</label>
                                        <div class="col-sm-9">
                                            <KeyWordTag :config="configKeyword" />
                                            <span v-if="configKeyword.error" class="text-danger">
                                                {{
                                                    $t('validateField.max255', {
                                                        field: $t('client.keyword')
                                                    })
                                                }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom07">種別</label>
                                        <div class="col-sm-4">
                                            <Multiselect
                                                :id="`select-client_type_id-object`"
                                                :value.sync="form.type_id"
                                                :options="OPTIONS_TYPE"
                                                :config.sync="form.configFieldTypeID"
                                                @select="handleSelectTypeId('select')"
                                                @remove="handleDeleteTypeID"
                                            />
                                        </div>
                                    </div>

                                    <div v-if="form?.type_id?.name" class="form-group row">
                                        <label class="col-sm-3" for="validationCustom07">{{ form?.type_id?.name }}</label>
                                        <div class="col-sm-9">
                                            <div class="d-flex flex-wrap">
                                                <div v-for="opt in listOptionType" :key="opt.id + 'opt'">
                                                    <InputCheckBox
                                                        class="mr-3"
                                                        :model.sync="form.option_type"
                                                        :id="`check_option_type_id_${opt.id}`"
                                                        :value="opt.id"
                                                        :label="opt.value"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div v-if="!id" class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/client/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            v-if="checkPermission(arrRoleClient[0]) || (checkPermission(arrRoleClient[1]) && $route.query.id)"
                            @click="formSubmit"
                            class="btn btn-primary"
                        >
                            {{ $t('btn.register') }}
                        </button>
                    </div>
                </div>

                <div v-else class="d-flex justify-content-center align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="text-center flex-fill">
                            <button
                                type="button"
                                class="btn btn-light mr-3"
                                @click="
                                    $router.push({
                                        path: '/client/list'
                                    })
                                "
                            >
                                {{ $t('btn.backList') }}
                            </button>
                            <button type="submit " @click="handleGetDetail" class="btn btn-info">
                                {{ $t('btn.goToDetail') }}
                            </button>
                        </div>
                    </div>
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="submit"
                            v-if="checkPermission(arrRoleClient[0]) || (checkPermission(arrRoleClient[1]) && $route.query.id)"
                            @click="formSubmit"
                            class="btn btn-primary"
                        >
                            {{ $t('btn.save') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>

        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
