import i18n from '@/i18n';

function initFormClient() {
    return {
        corp_number: '',
        name: '',
        kana_name: '',
        phone_number: '',
        profile: '',
        address_1: '',
        address_2: '',
        url: '',
        postal_code: '',
        province: '',
        type_id: null,
        option_type: [],
        configFieldName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldPhoneNumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.phone_number'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20', 'number-hyphens']
        },
        configFieldKanaName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.kana_name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'katakana']
        },
        configFieldPostalCode: {
            isNumber: true,
            maxlength: 10,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.postal_code'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required']
        },
        configFieldProvince: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.province'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldAddress1: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.address_1'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255'],
            placeholder: '市区町村'
        },
        configFieldAddress2: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.address_2'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255'],
            placeholder: '番地・その他'
        },
        configFieldUrl: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('client.url'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255', 'url']
        },
        configFieldCorpNumber: {
            error: false
        },
        configFieldTypeID: {
            trackBy: 'id',
            label: 'name',
            errorField: '種別',
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: [],
            openDirection: 'top'
        }
    };
}

function initFormClientSupport() {
    return {
        name: '',
        kana_name: '',
        phone_number: '',
        email: '',
        department: '',
        mobile_number: '',
        configFieldName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('clientSupport.name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldDepartment: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('clientSupport.department'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPhoneNumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('clientSupport.phone_number'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20', 'number-hyphens']
        },
        configFieldKanaName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('clientSupport.kana_name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['katakana']
        },
        configFieldMobileNumber: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('clientSupport.mobile_number'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20']
        },
        configFieldEmail: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('clientSupport.email'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['email', 'max255']
        }
    };
}

export { initFormClient, initFormClientSupport };
